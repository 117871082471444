.questions-tab {
    max-width: 90%;
    margin: 20px auto;
    padding: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.questions-component-heading {
    text-align: center;
    color: #333;
    font-family: 'Arial', sans-serif;
}

.questions-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

th {
    background-color: #4CAF50;
    color: white;
}
