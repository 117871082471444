.add-question-form{
    margin-left: 50px;
    margin-right: 50px;
}

.add-question-form {
    max-width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.add-question-form label, .add-question-form input, .add-question-form select {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.answer-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.answer-input input[type="text"] {
    flex-grow: 1;
    margin-right: 10px;
}

.add-question-form button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-question-form button:hover {
    background-color: #333;
}
