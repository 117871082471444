.image-quiz-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    padding: 20px;
}

.quiz-image img {
    width: 100%;
    height: auto;
    display: block;
}

.quiz-image {
    width: 100%;
}

.quiz-content {
    padding: 0px;
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    padding: 20px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-quiz-modal {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
    border-radius: 0 8px 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-quiz-options {
    list-style-type: none;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-quiz-heading {
    margin-top: 5%;
    font-size: 5em;
    color: rgb(218, 218, 218);
}

.image-quiz-question {
    color: rgb(218, 218, 218);
    font-size: 2em;
}

.image-quiz-question-ar {
    color: rgb(218, 218, 218);
    font-size: 2em;
    text-align: right;
}

.image-quiz-selected {
    background-color: #4CAF50;
    color: white;
}

.answer {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    margin-top: 10px;
    background-color: #2c232e;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 80%;
    text-align: center;
}

.answer.correct {
    background-color: #4CAF50;
}

.answer.incorrect {
    background-color: #f44336;
}

.answer-text-left {
    text-align: left;
    flex: 1;
}

.answer-text-right {
    text-align: right;
    flex: 1;
    direction: rtl;
}

.quiz-completed-message {
    color: #4CAF50;
    font-size: 2em;
    text-align: center;
    margin-top: 20px;
}
