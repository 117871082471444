body {
  background-image: url('../../../public/image1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.code-stack-heading {
  color: rgb(218, 218, 218);
}

.puzzle-heading {
  color: rgb(218, 218, 218);
  text-align: center;
  margin-top: 30px;
  background-color: #0000004f;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100%;
}

.queue-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.333%;
  margin-top: 5%;
}

.queue {
  border: 2px solid rgba(77, 77, 77, 0.3);
}

.queue-slot {
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  user-select: none;
  border-bottom: 2px solid rgba(77, 77, 77, 0.3);
}

.queue-slot:last-child {
  border-bottom: none;
}

.code {
  width: 39%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 5%;
}

.code-block {
  background-color: #282c34;
  color: #abb2bf;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow-x: auto;
  white-space: pre-wrap;
  font-family: 'Fira Code', monospace;
  font-size: 1em;
  text-align: left;
  width: 100%;
  cursor: grab;
}

.code-block.dragging {
  opacity: 0.5;
}

.code-block.placed {
  background-color: gray;
  cursor: default;
  color: darkgray;
}

body.grabbing * {
  cursor: grabbing !important;
}
