/* PuzzleModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 50%;
  position: relative;
  padding: 0;
  background-color: #282c3400;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.output-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282c3400;
  color: #abb2bf;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow-x: auto;
  white-space: pre-wrap;
  font-family: 'Fira Code', monospace;
  font-size: 2em;
  text-align: left;
  width: 100%;
  margin: 0;
}

.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
