.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
    border-radius: 8px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}
.start-quiz-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.3);
    z-index: 3;
    border-radius: 8px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.quiz-heading {
    font-size: 5em;
    color: rgb(218, 218, 218);
}

.quiz-question-en {
    color: rgb(218, 218, 218);
    font-size: 3em;
    text-align: left;
}

.quiz-question-ar {
    color: rgb(218, 218, 218);
    font-size: 3em;
    text-align: right;
}

.quiz-options {
    list-style-type: none;
    padding: 0; 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.question {
    margin-bottom: 20px;
}

.answer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 5px 0;
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 2em;
    text-align: center;
    width: 80%;
}

.answer:hover {
    background-color: #0056b3;
}

.selected {
    background-color: #4CAF50;
    color: white;
}

.button-enabled {
    padding: 10px 15px;
    background-color: #2c232e;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    font-size: 50px;
}

.button-disabled {
    padding: 10px 15px;
    background-color: #2c232e;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    font-size: 50px;
    opacity: 0.5;
    cursor: not-allowed;
}

input[type="text"] {
    width: 100%;
    padding: 12px;
    font-size: 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.result-message {
    font-size: 4em;
    color: rgb(218, 218, 218);
    margin-top: 20px;
    text-align: center;
}

.answer.correct {
    background-color: green;
    color: white;
}

.answer.incorrect {
    background-color: red;
    color: white;
}

.option_style {
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 2em;
}

.answer-text-left {
    text-align: left;
    flex: 1;
}

.answer-text-right {
    text-align: right;
    flex: 1;
}

.order-options-style {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}







/* .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 95%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
    border-radius: 8px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}
.start-quiz-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.3);
    z-index: 3;
    border-radius: 8px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.quiz-heading {
    font-size: 5em;
    color: rgb(218, 218, 218);
}

.quiz-question-en {
    color: rgb(218, 218, 218);
    font-size: 3em;
    text-align: left;
}

.quiz-question-ar {
    color: rgb(218, 218, 218);
    font-size: 3em;
    text-align: right;
}

.quiz-options {
    list-style-type: none;
    padding: 0; 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.question {
    margin-bottom: 20px;
}

.answer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 5px 0;
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 2em;
    text-align: center;
    width: 80%;
}

.answer:hover {
    background-color: #0056b3;
}

.selected {
    background-color: #4CAF50;
    color: white;
}

.button-enabled {
    padding: 10px 15px;
    background-color: #2c232e;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    font-size: 50px;
}

.button-disabled {
    padding: 10px 15px;
    background-color: #2c232e;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    font-size: 50px;
    opacity: 0.5;
    cursor: not-allowed;
}

input[type="text"] {
    width: 100%;
    padding: 12px;
    font-size: 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.result-message {
    font-size: 4em;
    color: rgb(218, 218, 218);
    margin-top: 20px;
    text-align: center;
}

.answer.correct {
    background-color: green;
    color: white;
}

.answer.incorrect {
    background-color: red;
    color: white;
}

.option_style {
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 2em;
}

.answer-text-left {
    text-align: left;
    flex: 1;
}

.answer-text-right {
    text-align: right;
    flex: 1;
}

.order-options-style {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
} */
