/* .admin-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
} */

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab {
    cursor: pointer;
    padding: 10px 20px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
}

.tab:hover {
    background-color: #e0e0e0;
}

.tab.active {
    background-color: #ccc;
}

.admin-header {
    padding: 10px;
    color: rgb(218, 218, 218);
}

.results-tab {
    /* Add styles for results tab content */
}

.add-questions-tab {
    /* Add styles for add questions tab content */
}
