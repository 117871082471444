
  .heading-decryption{
    margin-top: 15%;
    font-size: 80px;
    color: rgb(218, 218, 218);
  }
  
  .decryption-input {
    margin: 10px 0;
    padding: 12px; 
    width: 100%;
    max-width: 70%;
    font-size: 30px; 
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .decrypt-button {
    padding: 10px 15px;
    background-color: #2c232e;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 30px;
    font-size: 50px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .code-block-decrypted {
    background-color: #282c34;
    color: #abb2bf;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: pre-wrap;
    font-family: 'Fira Code', monospace; 
    font-size: 3em;
    display: flex;
    width: 50%;
    height: 20vh;
    margin: auto;
    overflow-wrap: break-word;
    max-width: 100%;
  }
  .decrypt-container{
    text-align: center;
  }
