/* ResultsPage.css */
.results-tab {
    max-width: 90%;
    margin: 20px auto;
    padding: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.result-heading {
    text-align: center;
    color: #333;
    font-family: 'Arial', sans-serif;
}

.result-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

th, td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #4CAF50;
    color: white;
}

tr:hover {
    background-color: #f5f5f5;
}

/* This will style the even rows differently to improve readability */
tr:nth-child(even) {
    background-color: #e7e7e7;
}

td {
    font-family: 'Arial', sans-serif;
    color: #555;
}

.pagination {
    text-align: center;
    margin-top: 20px;
}

.page-control-button, .page-number-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.page-control-button[disabled], .page-number-button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
}

.page-number-button.active {
    background-color: #333;
}

.page-control-button:hover, .page-number-button:hover:not([disabled]) {
    background-color: #333;
}

.page-control-button:focus, .page-number-button:focus {
    outline: none;
}