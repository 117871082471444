.register-user {
    font-size: 3em;
    color: rgb(218, 218, 218);
}

.new-register {
    padding: 10px 15px;
    background-color: #2c232e;
    color: white;
    border: none;
    border-radius: 10px;
    margin: 20px;
    cursor: pointer;
    font-size: 16px;
}