.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-content {
  position: relative;
  width: 60%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success-encrypt-message {
  color: #fff;
  font-size: 4em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.message-text-left {
  text-align: left;
  flex: 1;
}

.message-text-right {
  text-align: right;
  flex: 1;
}
