.image-gallery-container {
    height: 100vh;
    width: 100%;
    position: relative;
}

.image-gallery-image-container {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-gallery-image-wrapper {
    margin-bottom: 20px;
}

.image-gallery-image {
    max-width: 100%;
    height: auto;
}

.image-gallery-scroll-button {
    position: absolute;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 20px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;
}

.image-gallery-scroll-button-up {
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
}

.image-gallery-scroll-button-down {
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
}

.image-gallery-scroll-button-english {
    bottom: 10px;
    left: calc(50% - 160px); /* Adjust the value to position the button */
    transform: translateX(-60%);
}

.image-gallery-scroll-button-arabic {
    bottom: 10px;
    left: calc(50% + 160px); /* Adjust the value to position the button */
    transform: translateX(-40%);
}
