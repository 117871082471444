.heading-encryption {
  margin-top: 15%;
  font-size: 80px;
  color: rgb(218, 218, 218);
  margin-top: 0;
}

.encryption-container {
  text-align: center;
}

.quiz-section {
  margin-top: 30px;
}

.rot13-table {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  width: 50%; /* Adjust this value as needed */
}

.quiz-question-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.encrypt-quiz-question.english {
  text-align: left;
  direction: ltr;
  font-size: 2em;
  color: #ffffff;
}

.encrypt-quiz-question.arabic {
  text-align: right;
  direction: rtl;
  font-size: 2em;
  color: #ffffff;
}

.quiz-options {
  list-style-type: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quiz-options li {
  width: 100%;
}

.encrypt-answer {
  padding: 10px 20px;
  margin: 5px 0;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 2em;
  text-align: center;
  width: 80%;
}

.encrypt-answer:hover {
  background-color: #0056b3;
}

.encrypt-answer.correct {
  background-color: green;
  color: white;
}

.encrypt-answer.incorrect {
  background-color: red;
  color: white;
}

.encrypt-start-quiz-modal{
  position: fixed;
  top: 65%;
  left: 50%;
  margin-top: 30px;
  transform: translate(-50%, -50%);
  width: 95%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: rgba(0,0,0,0.3);
  z-index: 3;
  border-radius: 8px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}

.encrypt-quiz-question {
  width: 50%;
  padding: 0 10px;
  box-sizing: border-box;
}

.quiz-options li {
  margin: 10px 0;
}

.quiz-submit-button {
  padding: 10px 15px;
  background-color: #2c232e;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 30px;
  margin-top: 20px;
}

.encrypt-highlight {
  color: red;
}

.quiz-submit-button:disabled {
  background-color: grey;
  cursor: not-allowed;
  opacity: 0.6;
}
